<template>
  <b-card>
    <div
      class="d-flex align-items-center justify-content-center justify-content-md-start"
    >
      <div
        class="p-2 button-filter"
        :class="{ active: filter_status === 'current_and_later' }"
        @click="setFilter('current_and_later')"
      >
        Sesi Mentoring
      </div>
      <div
        class="p-2 button-filter"
        :class="{ active: filter_status === 'finished' }"
        @click="setFilter('finished')"
      >
        Sesi Berakhir
      </div>
    </div>
    <Table
      :result="result"
      :sessionData="sessionData"
      :is-loading="isLoading"
      :get-data="getData"
      :infiniteId="infiniteId"
      :current-page="currentPage"
    />
  </b-card>
</template>

<script>
import io from "socket.io-client";
import Table from "@/components/mentorship-mentor/Table.vue";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      sessionData: [],
      groupList: [],
      filter_status: "current_and_later",
      infiniteId: +new Date(),
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));

    this.socket = io(process.env.API_BASE_URL, {
      auth: {
        token: "Bearer " + localStorage.getItem("accessToken"),
      },
      query: {
        auto_join: true,
        unique_uuid: userData.uuid,
        type: "mentorship-realtime-schedule-time",
      },
    });

    this.socket.on("mentorship-listen-realtime-schedule", (response) => {
      response.forEach((element) => {
        const idx = this.sessionData.findIndex((session) => {
          return session.uuid === element.uuid;
        });
        if (idx !== -1) {
          this.sessionData[idx].status = element.status;
          console.log(element);
        }
      });
    });

    this.socket.emit(
      "mentorship-send-realtime-schedule",
      {
        is_mentor: true,
      },
      function (data) {
        console.log("EROOR", data);
      }
    );
  },
  methods: {
    getData($state) {
      this.$http
        .get("/api/v1/mentor/mentorship/booked-schedules", {
          params: {
            page: this.currentPage,
            filter_status: this.filter_status,
          },
        })
        .then(({ data }) => {
          if (data.data.items.length) {
            this.currentPage += 1;
            this.sessionData.push(...data.data.items);
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    setFilter(filterStatus) {
      this.filter_status = filterStatus;
      this.currentPage = 1;
      this.sessionData = [];
      this.infiniteId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-filter {
  cursor: pointer;
  &.active {
    font-weight: bold;
    color: #ea359f;
    border-bottom: 2px solid #ea359f;
  }
}

.card-body {
  padding: 0;
}
</style>
