<template>
  <div>
    <router-link
      v-for="sessions in sessionList"
      :to="'/mentorship-session-chat/' + sessions.uuid"
      class="d-flex border-bottom text-dark p-2"
      :class="getSessionClass(sessions.status)"
      style="gap: 16px"
      :key="sessions.uuid"
    >
      <img
        :src="sessions.user.avatar || require(`@/assets/images/avatars/null-profile.webp`)"
        class="mentor__image"
        :alt="sessions.user.name"
      />
      <div class="d-flex flex-column" style="gap: 8px">
        <h4 class="mb-0 fw-bold">{{ sessions.user.name }}</h4>
        <h5 class="mb-0 fw-bold">{{ sessions.topic.title }}</h5>
        <p class="mb-0" v-if="sessions.status === 'ongoing'">
          Jadwal sesi dimulai.
        </p>
        <p class="mb-0" v-if="sessions.status === 'incoming'">
          Jadwal sesi belum dimulai.
        </p>
        <p class="mb-0" v-if="sessions.status === 'finished'">
          Jadwal sesi kamu sudah selesai. Klik untuk baca chat kamu.
        </p>
        <div class="d-flex align-items-center" style="gap: 4px">
          <img
            src="@/assets/images/icons/clock-ongoing.png"
            alt=""
            v-if="sessions.status === 'ongoing'"
          />
          <img
            src="@/assets/images/icons/clock-incoming.png"
            alt=""
            v-if="sessions.status === 'incoming'"
          />
          <img
            src="@/assets/images/icons/circle-checked.png"
            alt=""
            v-if="sessions.status === 'finished'"
          />
          <div class="fw-bold">{{ sessions.date }},</div>
          <div class="fw-bold">
            {{ sessions.start_time }}
          </div>
        </div>
      </div>
    </router-link>
    <infinite-loading :identifier="infiniteIdData" @infinite="getData">
      <div slot="spinner">Loading...</div>
      <div slot="no-more"></div>
      <div slot="no-results" class="p-2">
        <img src="@/assets/images/avatars/session-data-empty.png" alt="" />
      </div>
    </infinite-loading>
    <!-- <b-table
        striped
        hover
        :items="sessionList"
        :fields="fields"
        :busy="isLoadingComponent"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
  
        <template v-slot:cell(no)="{ index }">
          {{ (currentPageList - 1) * 10 + index + 1 }}
        </template>
  
        <template v-slot:cell(topic)="{ item }">
          {{ item.topic.title }}
        </template>
  
        <template v-slot:cell(mentee)="{ item }">
          <div class="d-flex align-items-center" style="gap: 10px">
            <img
              :src="
                item.user.avatar ||
                require(`@/assets/images/avatars/null-profile.webp`)
              "
              class="mentor__image"
              :alt="item.user.name"
            />
            {{ item.user.name }}
          </div>
        </template>
  
        <template v-slot:cell(date)="{ item }">
          {{ item.date }}
          <div class="d-flex">{{ item.start_time }} - {{ item.end_time }}</div>
        </template>
      </b-table>
  
      <div
        v-if="resultData.itemsPerPage > 0"
        class="mt-3 d-flex justify-content-between align-items-center"
      >
        <small
          >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
          {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
          {{ resultData.totalItems }}</small
        >
        <b-pagination
          class="justify-content-end"
          v-model="currentPageList"
          :total-rows="resultData.totalItems"
          :per-page="resultData.itemsPerPage"
          aria-controls="my-table"
          @change="getData"
        ></b-pagination>
      </div> -->
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    InfiniteLoading,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    sessionData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    infiniteId: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    sessionData(value) {
      this.sessionList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    infiniteId(value) {
      this.infiniteIdData = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      sessionList: [],
      infiniteIdData: +new Date(),
    };
  },

  methods: {
    getSessionClass(status) {
      if (status === "ongoing") {
        return "bg-ongoing";
      } else if (status === "incoming") {
        return "bg-incoming";
      } else {
        return "bg-finished";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mentor__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.fw-bold {
  font-weight: 700;
}

.bg-ongoing {
  background: #f9fbff;
}
.bg-incoming {
  background: #fcedf1;
}
.bg-finished {
  background: #e8fffa;
}
</style>
